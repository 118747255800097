<template>
  <div>
    <form id="form" class="ma-2" @submit="checkForm">
      <input v-if="editItemId" type="hidden" name="id" id="id" v-model="newCharge.id" />
      <v-toolbar-title>Charge Details</v-toolbar-title>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            class="ml-1 mr-1"
            disabled
            outlined
            dense
            label="Selected Client"
            type="text"
            name="client"
            id="client"
            v-model="newCharge.client"
            prepend-inner-icon="business"
          ></v-text-field>
        </v-col>
        <v-col v-if="!recurring" cols="12" sm="12" md="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="newCharge.chargeDate"
                label="Date to apply Charge"
                prepend-inner-icon="event"
                readonly
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="newCharge.chargeDate" @input="menu2 = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-select
            v-model="newCharge.chargeCode"
            :items="chargeCodes"
            item-text="name"
            item-value="code"
            label="Select Charge Code"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="recurring">
        <v-col cols="12">
          <header>Recurring Bill</header>
        </v-col>
        <v-col cols="12">
          <v-radio-group class="mt-0" v-model="newCharge.recurring" :row="true">
            <v-radio value="weekly" label="Weekly"></v-radio>
      
            <v-menu
              v-if="setDate"
              v-model="menuR"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-if="setDate"
                  v-model="newCharge.recurringDate"
                  label="Date which the Charge is applied each month."
                  prepend-inner-icon="event"
                  readonly
                  dense
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="setDate"
                v-model="newCharge.recurringDate"
                @input="menuR = false"
              ></v-date-picker>
            </v-menu>
          </v-radio-group>
        </v-col>
      </v-row>
      <div>
        <v-text-field
          class="mt-1 mb-4"
          v-model="newCharge.chargeType"
          label="Charge Type"
          dense
          outlined
        ></v-text-field>
        <v-text-field
          class="mt-4 mb-4"
          v-model="newCharge.reference"
          label="Reference Number"
          dense
          outlined
        ></v-text-field>
        <v-text-field
          class="mt-4 mb-4"
          v-model="newCharge.clientReference"
          label="Client Reference"
          dense
          outlined
        ></v-text-field>
      </div>
      <div>
        <v-toolbar-title>Charge Amounts</v-toolbar-title>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="newCharge.UOM"
              label="Unit of Measurement"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="newCharge.billableUnits"
              label="Billable Units"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="newCharge.tariff"
              label="Tariff"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="number"
              v-model="newCharge.totalCharge"
              label="Total Cost"
              dense
              outlined
              step="0.01"
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="displaySubmit">
        <v-btn
          class="mt-4"
          :loading="loading"
          :disabled="loading"
          color="primary"
          type="submit"
        >Add Company Charge</v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import AdhocCharges from '@/services/AdhocCharges.js';

export default {
  props: [
    'newCharge',
    'displaySubmit',
    'loading',
    'editItemId',
    'recurring'
  ],
  data() {
    return {
      chargeCodes: [],
      errors: {
        shipment_id: null,
        pick_cost: null,
        charge_code: null,
        date_applied: null,
      },
      selectedChargeCode: null,
      menu2: false,
      menuR: false,
    }
  },
  methods: {
    async getChargeCodes() {
      // Use the BillingSnap to call the getBillingData() method
      AdhocCharges.getChargeCodes("")
      .then(
      (chargeCodes => {
        this.chargeCodes = chargeCodes;
      }))
    },
    // Form Check
    checkForm: async function (e) {
      e.preventDefault()
      if (this.recurring) {
        if (this.newCharge.reference && this.newCharge.totalCharge && this.newCharge.chargeCode && this.newCharge.recurring) {
          this.valid = true;
          this.$emit('createCharge', this.newCharge)
          return true;
        }
      } else { 
        if (this.newCharge.reference && this.newCharge.totalCharge && this.newCharge.chargeCode && this.newCharge.chargeDate) {
          this.valid = true;
          this.$emit('createCharge', this.newCharge)
          return true;
        }
      }
      this.errors = [];
      if (!this.newCharge.reference) {
        this.errors.reference = 'Reference is required.';
      }
      if (!this.newCharge.totalCharge) {
        this.errors.totalCharge = 'Total Charge required.';
      }
      if (!this.newCharge.chargeCode) {
        this.errors.charge_code = 'Charge Code required.';
      }
      if (!this.newCharge.chargeDate) {
        this.errors.chargeDate = 'Please select a Date.';
      }
      return false;
    },
  },
  created() {
    this.selectedChargeCode = this.newCharge.chargeCode
    this.getChargeCodes()
  },
  computed: {
    setDate: {
      // getter
      get: function () {
      if (this.newCharge.recurring && this.newCharge.recurring === "setDate") {
          return true;
        } else {
          return false;
        }
      }
    },

  },
  watch: {
    newCharge: {
        handler(newVal) {
            if (newVal.billableUnits && newVal.tariff) {
              // This was going to allow values to be computed but data can be string or number
              // this.newCharge.totalCharge = newVal.billableUnits * newVal.tariff;
            }
        },
        deep: true,
    },
  }
}
</script>

<style>
</style>