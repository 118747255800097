<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Download Invoice Data Prep Companies</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="selectedCompanies"
                  @change="getPrepCompanies"
                  :items="companies"
                  item-text="name"
                  item-value="code"
                  label="Select Company"
                  prepend-inner-icon="business"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-if="isPrepCompany"
                  menu-props="auto"
                  v-model="selectedPrepIds"
                  :items="prepCompaniesList"
                  item-text="name"
                  item-value="id"
                  label="Select Prep Company"
                  outlined
                  dense
                  multiple
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedInvoice"
                  :items="invoiceNumbers"
                  item-text="invoiceNumbers"
                  label="Select Invoice Number"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="selectedCompanies.length > 0 && selectedInvoice"
              >
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="createCompanyInvoice()"
                  >Download Billing Data</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <div v-if="companyBilliingData.length > 0">
        <v-container>
          <v-row>
            <v-btn
              @click="exportToExcel('xlsx')"
              type="button"
              :disabled="loading"
              class="
                mb-2
                v-btn v-btn--depressed
                theme--light
                v-size--small
                primary
              "
              >Excel</v-btn
            >
            <v-btn
              @click="exportToExcel('csv')"
              type="button"
              :disabled="loading"
              class="
                ml-2
                mb-2
                v-btn v-btn--depressed
                theme--light
                v-size--small
                primary
              "
              >CSV</v-btn
            >
            <v-btn
              @click="finalCommitBillingData()"
              type="button"
              :disabled="loading"
              class="
                ml-2
                mb-2
                v-btn v-btn--depressed
                theme--light
                v-size--small
                primary
              "
              >Commit Final Billing Data</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="createSalesSummary('xlsx')"
              type="button"
              :disabled="loading"
              color="teal"
              class="
                ml-2
                mb-2
                v-btn v-btn--depressed
                theme--light
                v-size--small
                white--text
              "
              >Create Sales Summary</v-btn
            >
          </v-row>
        </v-container>
      </div>
      <v-dialog v-model="dialog" max-width="75vw">
        <v-card>
          <v-card-title>
            <span class="headline">Edit {{ editedItem.shipment_id }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <charge-form
                :newCharge="editedItem"
                :editItemId="true"
              ></charge-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        class="mb-6"
        v-for="company in companyBilliingData"
        v-bind:key="company.id"
      >
        <v-card v-if="combinedChargeCodes.length > 0" class="mb-4">
          <v-container>
            <h2 class="title">{{ companyName }} Summary</h2>
            <div v-for="charge in combinedChargeCodes" v-bind:key="charge.id">
              {{ charge.code }} - {{ charge.name }} -
              {{ renderCurrency(charge.amount) }}
            </div>
          </v-container>
        </v-card>
        <div class="snapBillingTable" v-if="company.data.length > 0">
          <v-card>
            <v-card-title>
              {{ company.company_name }} - {{ company.prep_name }} Billing Data
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="snapHeaders"
              :items="company.data"
              :items-per-page="5"
              :search="search"
              id="data-table"
            >
              <template v-slot:item.pick_cost="{ item }">
                <v-chip dark>{{ renderCurrency(item["pick_cost"]) }}</v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item, company.prep_id)"
                  >edit</v-icon
                >
                <v-icon small @click="deleteItem(item, company.prep_id)"
                  >delete</v-icon
                >
                <v-checkbox
                  v-model="selectedItemsToDelete"
                  v-bind:value="item.id"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-card>
          <div align="center" class="ma-7">
            <v-btn
              v-show="selectedItemsToDelete.length > 0"
              @click="confirmDeleteSelectedItems()"
              :disabled="loading"
              color="red darken-4"
              class="
                ml-2
                mb-2
                v-btn v-btn--depressed
                theme--light
                v-size--small
                white--text
              "
              >Delete Selected Charges</v-btn
            >
          </div>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="5000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import CompaniesUtil from "@/services/CompaniesUtil.js";
import CommitedData from "@/services/CommitedData.js";
import AdhocCharges from "@/services/AdhocCharges.js";
import ChargeForm from "../components/forms/ChargeForm";
import * as XLSX from 'xlsx'
import BillingSnap from "@/services/BillingSnap.js";

export default {
  components: {
    ChargeForm,
  },
  data() {
    return {
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      // Dialog
      dialog: false,
      errors: {
        shipment_id: null,
        pick_cost: null,
        charge_code: null,
        date_applied: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => typeof value == Number || "Value must be a number",
      },
      // Upload
      search: "",
      data: [],
      // Company
      companies: [],
      selectedCompanies: [],

      companyName: null,
      valid: false,
      // Query
      query: {
        companyCode: null,
        companyId: null,
        dateShippedFrom: null,
        dateShippedTo: null,
        setInvoiceDate: null,
      },
      queryRun: false,
      // Billing Data
      snapBillingData: [],
      sortedBillingData: [],
      adhocCharges: [],
      processedData: [],
      // Company Margins
      companyMargins: [],
      // Snap Table
      snapHeaders: [
        { text: "Client", value: "company_code" },
        { text: "Charge Date", value: "charge_date" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "client_reference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billable_units" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "total_charge" },
        { text: "Charge Code", value: "charge_code" },
        { text: "Actions", value: "action", sortable: false },
      ],
      // Edit
      editedIndex: null,
      editedItem: [],
      // Return Message
      returnMessage: "",
      // Charge Codes
      chargeCodes: [],
      selectedChargeCode: null,
      chargeCode: null,
      // Xero Export
      xeroExportData: [],
      // Combined Charge Codes
      combinedChargeCodes: [],
      // Check Invoice Date Set
      invoiceDateSet: true,
      // Combined Data
      companyBilliingData: [],
      // Combined Xero
      xeroBillingData: [],
      // Invoice Numbers
      selectedInvoice: null,
      invoiceNumbers: [],
      goCardlessTemplate: [],
      currentLoadedInvoiceNumber: null,
      selectedItemsToDelete: [],

      isPrepCompany: true,
      prepCompaniesList: [],
      selectedPrepIds: null,
    };
  },
  methods: {
    async getPrepCompanies(companyCode) {
      this.loading = true;
      BillingSnap.getPrepCompanies(companyCode)
        .then((response) => {
          this.loading = false;
          this.prepCompaniesList = response;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    },
    // Edit & Delete
    async getChargeCodes(companyId) {
      // Use the BillingSnap to call the getBillingData() method
      return AdhocCharges.getChargeCodes(companyId)
        .then(
          ((chargeCodes) => {
            this.$set(this, "chargeCodes", chargeCodes);
            this.companies = this.sortFunc(this.companies);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    setChargeCode() {
      if (this.editedItem) {
        let editChargeCode = this.chargeCodes.find(
          (code) => code.name === this.selectedChargeCode
        );
        this.editedItem.charge_code = editChargeCode.code;
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    editItem(item, prepId) {
      let selectedCompany = this.companyBilliingData.find(
        (company) => company.company_code == item.company_code
      );
      this.editedItem = {
        id: item.id,
        company_id: selectedCompany.company_id,
        client: selectedCompany.company_code,
        chargeDate: item.charge_date,
        chargeType: item.charge_type,
        reference: item.reference,
        clientReference: item.client_reference,
        UOM: item.UOM,
        billableUnits: item.billable_units,
        tariff: item.tariff,
        totalCharge: item.total_charge,
        chargeCode: item.charge_code.toString(),
        invoiceNumber: this.currentLoadedInvoiceNumber,
        prep_id: prepId,
      };
      this.selectedChargeCode = this.editedItem.charge_code;
      this.dialog = true;
    },
    save() {
      CommitedData.updateCharge(this.editedItem).then((response) => {
        if (response.status > 202) {
          this.snackbar = true;
          this.text = `Error: ${response.errorMessage[0]}`;
          this.loading = false;
          return false;
        } else {
          this.text = `Edited ${response.successMessage[0]} successfully.`;
          this.close();
          setTimeout(() => {
            this.snackbar = true;
            this.createCompanyInvoice();
          }, 750);
        }
      });
    },
    async deleteItem(item, prepId) {
      if (confirm("Are you sure you want to delete this item?")) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", item.id);
        urlencoded.append("prep_id", prepId);
        CommitedData.deleteCharge(urlencoded).then(
          ((returnMessage) => {
            this.snackbar = true;
            this.text = `Deleted ${item.reference} from Committed Data.`;
            this.$set(this, "returnMessage", returnMessage);
            this.loading = false;
            setTimeout(() => {
              this.createCompanyInvoice();
            }, 300);
          }).bind(this)
        );
      }
    },
    async confirmDeleteSelectedItems() {
      let counter = this.selectedItemsToDelete.length;
      if (
        confirm(
          "Are you sure you want to delete the selected " + counter + " items"
        )
      ) {
        this.snackbar = true;
        this.text = "Deleting Charges";
        this.deleteSelectedItems(counter);
      }
    },
    async deleteSelectedItems(counter) {
      this.loading = true;
      if (this.selectedItemsToDelete.length > 0) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
        urlencoded.append("id", this.selectedItemsToDelete[0]);
        CommitedData.deleteCharge(urlencoded).then(() => {
          this.selectedItemsToDelete.shift();
          if (counter > 1) {
            this.deleteSelectedItems(counter - 1);
          } else {
            this.loading = false;
            this.snackbar = true;
            this.text = "Successfully Deleted Charges";
            setTimeout(() => {
              this.createCompanyInvoice();
            }, 150);
          }
        });
      }
    },
    // Export Settings
    exportToExcel(type) {
      this.xeroExportData = [];
      let detailedData = [];

      let writeCounter = 0;
      this.companyBilliingData.forEach((company) => {
        if (company.data.length > 0) {
          let tempArray = [];
          company.data.forEach((row) => {
            let tempItem = {
              company_code: row.company_code,
              prep_id: company.prep_id,
              charge_date: row.charge_date,
              charge_type: row.charge_type,
              reference: row.reference,
              client_reference: row.client_reference,
              UOM: row.UOM,
              billable_units: row.billable_units,
              tariff: row.tariff,
              total_charge: row.total_charge,
              charge_code: row.charge_code,
              invoiceNumber: this.selectedInvoice,
            };
            tempArray.push(tempItem);
            detailedData.push(tempItem);
          });
          var elt = tempArray;
          var wb = XLSX.utils.book_new();
          var ws = XLSX.utils.json_to_sheet(elt);
          XLSX.utils.book_append_sheet(
            wb,
            ws,
            company.company_code + " Detailed Data"
          );

          writeCounter++;
          setTimeout(() => {
            this.writeFile(wb, company.prep_name + "DetailedData." + type);
          }, 1 + 500 * writeCounter);
          // XLSX.writeFile(wb, company.company_code + "DetailedData." + type);
        }
      });
      if (detailedData.length > 0) {
        var wb2 = XLSX.utils.book_new();
        var ws2 = XLSX.utils.json_to_sheet(detailedData);
        XLSX.utils.book_append_sheet(wb2, ws2, "Combined Detailed Data");
        writeCounter++;
        setTimeout(() => {
          this.writeFile(wb2, "Combined Detailed Data." + type);
        }, 1 + 500 * writeCounter);
        // XLSX.writeFile(wb2, "CombinedDetailedData." + type);
      }

      this.xeroBillingData.forEach((company) => {
        company.data.forEach((element) => {
          let tempArray = {
            ContactName: company.company_name + " " + company.prep_name,
            EmailAddress: null,
            POAddressLine1: null,
            POAddressLine2: null,
            POAddressLine3: null,
            POAddressLine4: null,
            POCity: null,
            PORegion: null,
            POPostalCode: null,
            POCountry: null,
            InvoiceNumber: `${company.company_code}${company.prep_id}-${element.invoice_number}`,
            Reference: null,
            InvoiceDate: element.invoice_date,
            DueDate: element.due_date,
            Total: null,
            InventoryItemCode: null,
            Description: element.description,
            Quantity: 1,
            UnitAmount: parseFloat(element.unit_amount),
            Discount: null,
            AccountCode: element.account_code,
            TaxType: element.tax_type,
            TaxAmount: null,
            TrackingName1: element.tracking_name1,
            TrackingOption1: element.tracking_option1,
            TrackingName2: null,
            TrackingOption2: null,
            Currency: null,
            BrandingTheme: null,
          };
          this.xeroExportData.push(tempArray);
        });
      });
      if (this.xeroExportData.length > 0) {
        var elt3 = this.xeroExportData;
        var wb3 = XLSX.utils.book_new();
        var ws3 = XLSX.utils.json_to_sheet(elt3);
        XLSX.utils.book_append_sheet(wb3, ws3, " Xero Data");
        // XLSX.writeFile(wb3, "XeroData." + type);
        writeCounter++;
        setTimeout(() => {
          this.writeFile(wb3, "XeroData." + type);
        }, 1 + 500 * writeCounter);
      }
    },
    async createSalesSummary(type) {
      this.xeroExportData = [];

      //sales summary variables
      let summaryReport = [];
      let grandTotals = {};
      let globalTotalAmount = 0;
      grandTotals.Company = "Grand Total";

      //for each xeroBilling Company generate xero report and sales summary
      for (let j = 0; j < this.xeroBillingData.length; j++) {
        let company = this.xeroBillingData[j];

        if (company.data.length > 0) {
          company.data.forEach((element) => {
            let tempArray = {
              ContactName: company.company_name + " " + company.prep_name,
              EmailAddress: null,
              POAddressLine1: null,
              POAddressLine2: null,
              POAddressLine3: null,
              POAddressLine4: null,
              POCity: null,
              PORegion: null,
              POPostalCode: null,
              POCountry: null,
              InvoiceNumber: `${company.company_code}${company.prep_id}-${element.invoice_number}`,
              Reference: null,
              InvoiceDate: element.invoice_date,
              DueDate: element.due_date,
              Total: null,
              InventoryItemCode: null,
              Description: element.description,
              Quantity: 1,
              UnitAmount: parseFloat(element.unit_amount),
              Discount: null,
              AccountCode: element.account_code,
              TaxType: element.tax_type,
              TaxAmount: null,
              TrackingName1: element.tracking_name1,
              TrackingOption1: element.tracking_option1,
              TrackingName2: null,
              TrackingOption2: null,
              Currency: null,
              BrandingTheme: null,
            };

            this.xeroExportData.push(tempArray);
          });

          //sales summary part
          let summaryArray = {
            Company: company.company_name + " " + company.prep_name,
          };
          let chargeCodes = await this.getChargeCodes(company.company_id);
          if (!chargeCodes) {
            this.snackbar = true;
            this.text = `Failed to get charge codes for selected ${company.company_name}`;
            this.loading = false;
            return;
          }

          let combinedCharges = 0;
          //loop through all charge codes, compute amounts and add totals
          this.chargeCodes.forEach((chargeCode) => {
            if (chargeCode.code && chargeCode.TrackingOption1) {
              let record = company.data.find(
                (element) => element.account_code == chargeCode.code
              );
              let amount = record ? parseFloat(record.unit_amount) : 0;
              summaryArray[chargeCode.name] = amount;

              combinedCharges += amount;

              //Add to grandTotals Array
              grandTotals[chargeCode.name]
                ? (grandTotals[chargeCode.name] += amount)
                : (grandTotals[chargeCode.name] = amount);
              globalTotalAmount += amount;
            }
          });

          summaryArray["Grand Total"] = combinedCharges;
          summaryReport.push(summaryArray);
        }
      }

      //format chargeCode columns for last grandTotal row
      // this.chargeCodes.forEach((chargeCode) => {
      //   if (chargeCode.code && chargeCode.TrackingOption1) {
      //     grandTotals[chargeCode.name] ? grandTotals[chargeCode.name] = this.renderCurrency(grandTotals[chargeCode.name]) : this.renderCurrency(0);
      //   }
      // });
      grandTotals["Grand Total"] = globalTotalAmount;
      summaryReport.push(grandTotals);

      if (this.xeroExportData.length > 0) {
        var elt3 = this.xeroExportData;
        var wb3 = XLSX.utils.book_new();
        var ws3 = XLSX.utils.json_to_sheet(elt3);
        var firstSheet = XLSX.utils.json_to_sheet(summaryReport);
        XLSX.utils.book_append_sheet(wb3, firstSheet, "Sales   Summary");
        XLSX.utils.book_append_sheet(wb3, ws3, "Xero Data");
        XLSX.writeFile(wb3, "SalesSummary." + type);
      }
    },
    writeFile(wb, fileName) {
      XLSX.writeFile(wb, fileName);
    },
    sortFunc(array) {
      return array.slice().sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
      });
    },
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    setCompanyCode() {
      let company = this.companies.find(
        ({ name }) => name === this.companyName
      );
      if (company) {
        this.getCompanyData(company.id);
        this.query.companyCode = company.code;
        this.query.companyId = company.id;
      }
    },
    enableFileExport() {
      if (this.query.setInvoiceDate) {
        this.invoiceDateSet = false;
      }
    },
    // Company Data
    getCompaniesData() {
      // Use the BillingSnap to call the getBillingData() method
      return CompaniesUtil.getSnapCompanies()
        .then(
          ((companies) => {
            this.$set(this, "companies", companies);
            this.companies = this.sortFunc(this.companies);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },

    async getInvoiceNumbers() {
      // Use the BillingSnap to call the getBillingData() method
      return CommitedData.getInvoiceNumbers()
        .then(
          ((invoiceNumbers) => {
            this.$set(this, "invoiceNumbers", invoiceNumbers);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async createCompanyInvoice() {
      this.companyBilliingData = [];
      //clear data so it reloads with new one instead of simply adding to it
      this.xeroBillingData = [];

      let chargeCodes = await this.getChargeCodes("");
      if (!chargeCodes) {
        this.snackbar = true;
        this.text = `Failed to get charge codes`;
        this.loading = false;
        return;
      }
      let tempCompany = this.companies.find(
        ({ code }) => code === this.selectedCompanies
      );

      this.selectedPrepIds.forEach((prepId) => {
        let tempPrepComp = this.prepCompaniesList.find(
          ({ id }) => id === prepId
        );

        // this.getCompanyData(tempCompany.id);
        let request = {
          company_id: tempCompany.id,
          invoice_number: this.selectedInvoice,
          prep_id: prepId,
        };

        CommitedData.getDetailedBillingData(request)
          .then(
            ((response) => {
              "response", response;
              this.companyBilliingData.push({
                company_code: tempCompany.code,
                company_name: tempCompany.name,
                company_id: tempCompany.id,
                prep_id: prepId,
                prep_name: tempPrepComp.name,
                data: response,
              });
            }).bind(this)
          )
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.message}`;
            this.loading = false;
            return;
          });
        CommitedData.getXeroBillingData(request)
          .then(
            ((response) => {
              "response", response;
              this.xeroBillingData.push({
                company_code: tempCompany.code,
                company_name: tempCompany.name,
                company_id: tempCompany.id,
                prep_name: tempPrepComp.name,
                prep_id: prepId,
                data: response,
              });
            }).bind(this)
          )
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.message}`;
            this.loading = false;
            return;
          });
      });
      this.currentLoadedInvoiceNumber = this.selectedInvoice;
    },
    async finalCommitBillingData() {
      let requestBody = [];
      this.companyBilliingData.forEach((companyData) => {
        let tempArray = {
          companyCode: companyData.company_code,
          dataLength: companyData.data.length,
          invoiceNumber: this.selectedInvoice,
          prep_id: companyData.prep_id,
        };
        requestBody.push(tempArray);
      });
      this.loading = false;
      let tempString = "";
      CommitedData.checkDataThenFinalCommit(requestBody)
        .then((response) => {
          //   this.companyBilliingData.forEach((companyData) => {
          //     CommitedData.createPDFInvoice(
          //       companyData.company_id,
          //       this.selectedInvoice
          //     ).then((msg) => {
          //       if (msg.status === 200) {
          //         if (msg.successMessage) {
          //           tempString += `${msg.successMessage[0]} `;
          //           this.text = `${tempString}`;
          //           this.snackbar = true;
          //         }
          //       }
          //     });
          //   });

          response.successMessage.forEach((message) => {
            tempString += `${message} `;
          });
          response.errorMessage.forEach((message) => {
            tempString += `${message} `;
          });
          this.text = `${tempString}`;
          this.snackbar = true;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error}`;
          this.loading = false;
          return;
        });
    },
    async loadInitialData() {
      this.loading = true;
      this.companies = await this.$root.sharedData.getCompanies();
      let invoiceNumber = await this.getInvoiceNumbers();
      if (this.companies.length > 0 && invoiceNumber) {
        this.loading = false;
        this.text = `Please choose Companies and Invoice Number to view Data.`;
        this.snackbar = true;
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}
</style>